import antdEsES from 'antd/es/locale/es_ES';
import esMsg from "../locales/es_UY.json";

const EsLang = {
  antd: antdEsES,
  locale: 'es-uy',
  messages: {
    ...esMsg
  },
};
export default EsLang;
