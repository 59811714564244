import axios from 'auth/FetchInterceptor'
import {env} from "../configs/EnvironmentConfig";

const JwtAuthService = {}

JwtAuthService.login = function (data) {
    let user = data.email;
    let password = data.password;

    let base64encodedData = Buffer.from(user + ':' + password).toString('base64');
    return axios({
        url: '/auth',
        method: 'post',
        headers: {
            'Authorization': 'Basic ' + base64encodedData
        }
    })
}

JwtAuthService.forgotPassword = function (email) {
    const data = {
        email: email,
        link: env.APP_BASE_URL + "/auth/restablecer-contrasena"
    }
    return axios({
        url: '/password-resets',
        method: 'post',
        data: data
    })
}

JwtAuthService.resetPassword = function (newPassword, token) {
    const data = {
        password: newPassword,
    }
    return axios({
        url: '/password-resets/' + token,
        method: 'put',
        data: data
    })
}

JwtAuthService.signUp = function (data) {
    return axios({
        url: '/users',
        method: 'post',
        headers: {
            'public-request': 'true'
        },
        data: data
    })
}

export default JwtAuthService
