import React, {lazy, Suspense} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import {APP_PREFIX_PATH} from 'configs/AppConfig'

export const AppViews = () => {
    return (
        <Suspense fallback={<Loading cover="content"/>}>
            <Switch>
                <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))}/>
                <Route path={`${APP_PREFIX_PATH}/ventas/`} component={lazy(() => import(`./daily-sales`))}/>
                <Route path={`${APP_PREFIX_PATH}/proveedores`} component={lazy(() => import(`./suppliers`))}/>
                <Route path={`${APP_PREFIX_PATH}/servicios`} component={lazy(() => import(`./services`))}/>
                <Route path={`${APP_PREFIX_PATH}/agro`} component={lazy(() => import(`./rurals`))}/>
                <Route path={`${APP_PREFIX_PATH}/clientes`} component={lazy(() => import(`./clients`))}/>
                <Route path={`${APP_PREFIX_PATH}/egresos`} component={lazy(() => import(`./expenses`))}/>
                <Route path={`${APP_PREFIX_PATH}/apps`} component={lazy(() => import(`./apps`))}/>
                <Route path={`${APP_PREFIX_PATH}/components`} component={lazy(() => import(`./components`))}/>
                <Route path={`${APP_PREFIX_PATH}/pages`} component={lazy(() => import(`./pages`))}/>
                <Route path={`${APP_PREFIX_PATH}/maps`} component={lazy(() => import(`./maps`))}/>
                <Route path={`${APP_PREFIX_PATH}/charts`} component={lazy(() => import(`./charts`))}/>
                <Route path={`${APP_PREFIX_PATH}/docs`} component={lazy(() => import(`./docs`))}/>
                <Route path={`${APP_PREFIX_PATH}/comercio`} component={lazy(() => import(`./commerces`))}/>
                <Route path={`${APP_PREFIX_PATH}/industria`} component={lazy(() => import(`./industry`))}/>
                <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards`}/>
            </Switch>
        </Suspense>
    )
}

export default React.memo(AppViews);
