const dev = {
    API_ENDPOINT_URL: 'http://localhost:9000/api/v1',
    APP_BASE_URL: 'http://localhost:5000'
};

const prod = {
    API_ENDPOINT_URL: 'https://api.madererasdeleste.com.uy/api/v1',
    APP_BASE_URL: 'https://admin.madererasdeleste.com.uy'
};

const test = {
    API_ENDPOINT_URL: 'https://api.test.com'
};

const getEnv = () => {
    switch (process.env.NODE_ENV) {
        case 'development':
            return dev
        case 'production':
            return prod
        case 'test':
            return test
        default:
            break;
    }
}

export const env = getEnv()
