import {NAV_TYPE_SIDE, SIDE_NAV_DARK} from 'constants/ThemeConstant';
import {env} from './EnvironmentConfig'
import {SIDE_NAV_LIGHT} from "../constants/ThemeConstant";

export const APP_NAME = 'Madereras del Este';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';

export const THEME_CONFIG = {
    navCollapsed: false,
    sideNavTheme: SIDE_NAV_LIGHT,
    locale: 'es',
    navType: NAV_TYPE_SIDE,
    topNavColor: '#dda021',
    headerNavColor: '#dda021',
    mobileNav: false
};
