import React from 'react';
import {Provider} from 'react-redux';
import store from './redux/store';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Views from './views';
import {notification} from "antd";
import 'moment/locale/es'
import moment from "moment";

function App() {

    moment.locale('es');

    notification.config({
        placement: 'topRight',
        duration: 5,
    });

    return (
        <div className="App">
            <Provider store={store}>
                <Router>
                    <Switch>
                        <Route path="/" component={Views}/>
                    </Switch>
                </Router>
            </Provider>
        </div>
    );
}

export default App;
