import React from "react";
import {Avatar, Dropdown, Menu} from "antd";
import {connect, useSelector} from 'react-redux'
import {LogoutOutlined, SettingOutlined} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import {signOut} from 'redux/actions/Auth';

const menuItem = [
    {
        title: "Configuración",
        icon: SettingOutlined,
        path: "/"
    },
]

export const NavProfile = ({signOut}) => {

    const {user} = useSelector(state => state.auth)

    const profileMenu = (
        <div className="nav-profile nav-dropdown">
            <div className="nav-profile-header">
                <div className="d-flex">
                    <Avatar size={45} src={user.picture}/>
                    <div className="pl-3">
                        <h4 className="mb-0">{user.name}</h4>
                        <span className="text-muted">{user.email}</span>
                    </div>
                </div>
            </div>
            <div className="nav-profile-body">
                <Menu>
                    <Menu.Item key={menuItem.legth + 1} onClick={e => signOut()}>
                        <span>
                          <LogoutOutlined className="mr-3"/>
                          <span className="font-weight-normal">Cerrar sesión</span>
                        </span>
                    </Menu.Item>
                </Menu>
            </div>
        </div>
    );
    return (
        <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
            <Menu className="d-flex align-item-center" mode="horizontal">
                <Menu.Item>
                    <Avatar src={user.picture}/>
                </Menu.Item>
            </Menu>
        </Dropdown>
    );
}

export default connect(null, {signOut})(NavProfile)
