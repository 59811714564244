import {
    AppstoreOutlined,
    DashboardOutlined,
    FileTextOutlined,
    PlusCircleOutlined,
    SafetyOutlined,
    StopOutlined,
    UnorderedListOutlined
} from '@ant-design/icons';
import {APP_PREFIX_PATH, AUTH_PREFIX_PATH} from 'configs/AppConfig'
import {
    GiBarbedWire,
    GiBulldozer,
    GiChainsaw,
    GiCow,
    GiCrosscutSaw,
    GiDeathSkull,
    GiMeat,
    GiNewBorn,
    GiReceiveMoney,
    GiShoppingCart,
    GiTakeMyMoney,
    GiWoodBeam,
    GiWoodPile,
    GiRanchGate
} from "react-icons/gi"
import {BsFillPeopleFill, BsFillPersonLinesFill, BsFillPersonPlusFill} from "react-icons/bs"
import {AiOutlineBarChart} from "react-icons/ai"


const dashBoardNavTree = [{
    key: 'dashboards',
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: 'sidenav.dashboard',
    icon: AiOutlineBarChart,
    breadcrumb: false,
    submenu: [
        {
            key: 'dashboards-default',
            path: `${APP_PREFIX_PATH}/dashboards/default`,
            title: 'sidenav.dashboard.default',
            icon: AiOutlineBarChart,
            breadcrumb: false,
            submenu: []
        }
    ]
}]

const salesNavTree = [{
    key: 'sales',
    path: `${APP_PREFIX_PATH}/ventas`,
    title: 'sidenav.sales',
    icon: AiOutlineBarChart,
    breadcrumb: false,
    submenu: [
        {
            key: 'sales.global',
            path: `${APP_PREFIX_PATH}/ventas/listado`,
            title: 'sales.global',
            icon: AiOutlineBarChart,
            breadcrumb: false,
            submenu: []
        }
    ]
}]

/* CUSTOM NAVIGATION TREES */
const suppliersNavTree = [{
    key: 'suppliers-header',
    path: `${APP_PREFIX_PATH}/proveedores`,
    title: 'sidenav.suppliers',
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [

        {
            key: 'suppliers.list',
            path: `${APP_PREFIX_PATH}/proveedores/listado`,
            title: 'sidenav.suppliers.list',
            icon: BsFillPersonLinesFill,
            breadcrumb: true,
            submenu: []
        },
        {
            key: 'suppliers.new',
            path: `${APP_PREFIX_PATH}/proveedores/nuevo`,
            title: 'sidenav.suppliers.new',
            icon: BsFillPersonPlusFill,
            breadcrumb: false,
            submenu: []
        }

    ]
}]

const servicesNavTree = [{
    key: 'services-header',
    path: `${APP_PREFIX_PATH}/servicios`,
    title: 'sidenav.services',
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
        {
            key: 'services.list',
            path: `${APP_PREFIX_PATH}/servicios/listado`,
            title: 'sidenav.services.list',
            icon: GiBulldozer,
            breadcrumb: true,
            submenu: []
        }

    ]
}]

const ruralNavTree = [{
    key: 'rural-header',
    path: `${APP_PREFIX_PATH}/agro`,
    title: 'sidenav.empty',
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
        {
            key: 'rural-header',
            path: `${APP_PREFIX_PATH}/agro`,
            title: 'sidenav.rural',
            icon: UnorderedListOutlined,
            breadcrumb: true,
            submenu: [
                {
                    key: 'rural.list',
                    path: `${APP_PREFIX_PATH}/agro/listado`,
                    title: 'sidenav.rural.list',
                    icon: GiCow,
                    breadcrumb: true,
                    submenu: []
                },
                {
                    key: 'rural.products',
                    path: `${APP_PREFIX_PATH}/agro/productos`,
                    title: 'sidenav.rural.products',
                    icon: GiMeat,
                    breadcrumb: true,
                    submenu: []
                },
                {
                    key: 'rural.birth',
                    path: `${APP_PREFIX_PATH}/agro/nacimiento`,
                    title: 'sidenav.rural.birth',
                    icon: GiNewBorn,
                    breadcrumb: true,
                    submenu: []
                },
                {
                    key: 'rural.death',
                    path: `${APP_PREFIX_PATH}/agro/muerte`,
                    title: 'sidenav.rural.death',
                    icon: GiDeathSkull,
                    breadcrumb: true,
                    submenu: []
                },
                {
                    key: 'rural.purchase',
                    path: `${APP_PREFIX_PATH}/agro/compras`,
                    title: 'sidenav.rural.purchases',
                    icon: GiShoppingCart,
                    breadcrumb: true,
                    submenu: []
                },
                {
                    key: 'rural.sale',
                    path: `${APP_PREFIX_PATH}/agro/ventas`,
                    title: 'sidenav.rural.sale',
                    icon: GiReceiveMoney,
                    breadcrumb: true,
                    submenu: []
                },
                {
                    key: 'rural.product.sale',
                    path: `${APP_PREFIX_PATH}/agro/venta-productos`,
                    title: 'sidenav.rural.product.sale',
                    icon: GiReceiveMoney,
                    breadcrumb: true,
                    submenu: []
                }
            ]
        },
    ]

}]

const clientsNavTree = [{
    key: 'clients.header',
    path: `${APP_PREFIX_PATH}/clientes`,
    title: 'sidenav.clients',
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
        {
            key: 'clients.list',
            path: `${APP_PREFIX_PATH}/clientes/listado`,
            title: 'sidenav.clients.list',
            icon: BsFillPeopleFill,
            breadcrumb: true,
            submenu: []
        }

    ]
}]

const financeNavTree = [{
    key: 'finance.header',
    title: 'sidenav.finance',
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
        {
            key: 'finance.expenses.list',
            path: `${APP_PREFIX_PATH}/egresos/listado`,
            title: 'finance.expenses.list',
            icon: GiTakeMyMoney,
            breadcrumb: true,
            submenu: []
        }

    ]
}]

const commerceNavTree = [{
    key: 'commerces-header',
    path: `${APP_PREFIX_PATH}/comercio`,
    title: 'sidenav.empty',
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
        {
            key: 'commerces-header',
            path: `${APP_PREFIX_PATH}/comercio`,
            title: 'sidenav.commerce',
            icon: AppstoreOutlined,
            breadcrumb: true,
            submenu: [
                {
                    key: 'commerces.list',
                    path: `${APP_PREFIX_PATH}/comercio/listado`,
                    title: 'sidenav.commerce.list',
                    icon: GiBarbedWire,
                    breadcrumb: true,
                    submenu: []
                },
                {
                    key: 'commerces.purchases',
                    path: `${APP_PREFIX_PATH}/comercio/producto/compras`,
                    title: 'sidenav.commerce.product.purchases',
                    icon: GiShoppingCart,
                    breadcrumb: true,
                    submenu: []
                },
                {
                    key: 'sidenav.commerces.product.sales',
                    path: `${APP_PREFIX_PATH}/comercio/producto/ventas`,
                    title: 'sidenav.commerces.product.sales',
                    icon: GiReceiveMoney,
                    breadcrumb: true,
                    submenu: []
                },
                {
                    key: 'commerces.firewood',
                    path: `${APP_PREFIX_PATH}/comercio/leña`,
                    title: 'sidenav.commerce.firewood',
                    icon: GiWoodPile,
                    breadcrumb: true,
                    submenu: []
                },
                {
                    key: 'commerces.firewood.purchases',
                    path: `${APP_PREFIX_PATH}/comercio/leña/compras`,
                    title: 'commerces.firewood.purchases',
                    icon: GiShoppingCart,
                    breadcrumb: true,
                    submenu: []
                },
                {
                    key: 'commerces.firewood.sales',
                    path: `${APP_PREFIX_PATH}/comercio/leña/ventas`,
                    title: 'commerces.firewood.sales',
                    icon: GiReceiveMoney,
                    breadcrumb: true,
                    submenu: []
                }
            ]
        },
    ]

}]

const industrialNavTree = [{
    key: 'industrial-header',
    path: `${APP_PREFIX_PATH}/industria`,
    title: 'sidenav.empty',
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
        {
            key: 'industrial-header',
            path: `${APP_PREFIX_PATH}/industria`,
            title: 'sidenav.industrial',
            icon: AppstoreOutlined,
            breadcrumb: true,
            submenu: [
                {
                    key: 'industrial.primary.list',
                    path: `${APP_PREFIX_PATH}/industria/primaria/listado`,
                    title: 'sidenav.industrial.primary',
                    icon: GiChainsaw,
                    breadcrumb: true,
                    submenu: []
                },
                {
                    key: 'industrial.secondary.list',
                    path: `${APP_PREFIX_PATH}/industria/secundaria/listado`,
                    title: 'sidenav.industrial.secondary',
                    icon: GiCrosscutSaw,
                    breadcrumb: true,
                    submenu: []
                },
                {
                    key: 'industrial.tertiary.list',
                    path: `${APP_PREFIX_PATH}/industria/terciaria/listado`,
                    title: 'sidenav.industrial.tertiary',
                    icon: GiWoodBeam,
                    breadcrumb: true,
                    submenu: []
                },
                {
                    key: 'industrial.gates.list',
                    path: `${APP_PREFIX_PATH}/industria/porteras`,
                    title: 'sidenav.industrial.gate',
                    icon: GiRanchGate,
                    breadcrumb: true,
                    submenu: []
                },
                {
                    key: 'industrial.sales.list',
                    path: `${APP_PREFIX_PATH}/industria/producto/ventas`,
                    title: 'sidenav.industrial.sale',
                    icon: GiReceiveMoney,
                    breadcrumb: true,
                    submenu: []
                },
            ]
        },
    ]

}]

/* ----------------------- */

const navigationConfig = [
    ...dashBoardNavTree,
    ...salesNavTree,
    ...financeNavTree,
    ...suppliersNavTree,
    ...servicesNavTree,
    ...ruralNavTree,
    ...commerceNavTree,
    ...industrialNavTree,
    ...clientsNavTree,
]

export default navigationConfig;
